import './Footer.css';
export const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-section about">
          <h2>About Us</h2>
          <p>
            We are a company dedicated to providing the best services and products to our customers.
            Our mission is to innovate and lead in our industry.
          </p>
        </div>

        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
          </ul>
        </div>

        <div className="footer-section contact">
          <h2>Contact Us</h2>
          <ul>
            <li>
              <span>Address:</span> 123 Main Street, City, Country
            </li>
            <li>
              <span>Email:</span> contact@company.com
            </li>
            <li>
              <span>Phone:</span> +123 456 7890
            </li>
          </ul>
        </div>

        <div className="footer-section social">
          <h2>Follow Us</h2>
          <ul>
            <li>
              <a href="https://facebook.com" target="_blank">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://twitter.com" target="_blank">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://linkedin.com" target="_blank">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="https://instagram.com" target="_blank">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Company Name. All rights reserved.</p>
      </div>
    </footer>
  );
};
