import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Home } from './components/Home';
import { NationalSecurity } from './components/NationalSecurity';
import { Footer } from './components/Footer';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/national-security" element={<NationalSecurity />} />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
