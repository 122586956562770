import { Carousel } from 'react-bootstrap';
import img1 from '../images/carousel1.jpg';
import img2 from '../images/carousel2.jpg';
import img3 from '../images/carousel3.jpg';
import './Home.css';
export const Home = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img id="carousel-img" src={img1} alt="First slide" />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img id="carousel-img" src={img3} alt="First slide" />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img id="carousel-img" src={img2} alt="First slide" />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
