import { useState } from 'react';
import './NavbarStyles.css';
import React from 'react';
import { Link } from 'react-router-dom';
const Navbar = () => {
  const [barState, setBarState] = React.useState(true);
  const handleBarClick = () => {
    setBarState(!barState);
  };
  return (
    <>
      <nav>
        <a href="index.html">
          <img src="../logo.jpg" height="100px" width="100px"></img>
        </a>
        <div>
          <ul id="navbar" className={barState ? '#navbar active' : '#navbar'}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/national-security">National Security</Link>
            </li>
            <li>
              <Link to="/defence&aerospace">Defence & Aerospace</Link>
            </li>
            <li>
              <Link to="/foreign-affairs">Foreign Affairs</Link>
            </li>
            <li>
              <Link to="/space-tech">Space Tech</Link>
            </li>
            <li>
              <Link to="/military-event">Military Event</Link>
            </li>
          </ul>
        </div>
        <div id="mobile" onClick={handleBarClick}>
          <i id="bars" className={barState ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
